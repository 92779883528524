import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/fbert/OneDrive/Documents/wwwBertramFamilyCom/GatsbyJS/src/components/layout-mdx.js";
import { LinkToPdf } from "../../components/uwe-mdx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/61c292aa89909dbc64ae676d36e8c3e4/4b190/uwe_med_hr.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQDBQb/xAAVAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABeUgUZ0hUCf/EABoQAAEFAQAAAAAAAAAAAAAAAAEAAgMSIQT/2gAIAQEAAQUCbiPQGzKgVW3M+//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/AYf/xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPwGn/8QAGxAAAgMAAwAAAAAAAAAAAAAAACEBERIQMkH/2gAIAQEABj8CSM3fnDRGUd5P/8QAGxAAAgIDAQAAAAAAAAAAAAAAAAERYSExkVH/2gAIAQEAAT8hRZBmAlNMbdcPR0yaSxAy00f/2gAMAwEAAgADAAAAEJwP/8QAFxEBAAMAAAAAAAAAAAAAAAAAAAERIf/aAAgBAwEBPxCMqf/EABcRAQADAAAAAAAAAAAAAAAAAAABEVH/2gAIAQIBAT8QbnH/xAAcEAADAQACAwAAAAAAAAAAAAABESEAQVExYXH/2gAIAQEAAT8QqxFUe+N5L8EACf3zoKyrfAPUDjp8xPKjRtB4OKAKyLDf/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Uwe Bertram",
            "title": "Uwe Bertram",
            "src": "/static/61c292aa89909dbc64ae676d36e8c3e4/4b190/uwe_med_hr.jpg",
            "srcSet": ["/static/61c292aa89909dbc64ae676d36e8c3e4/f93b5/uwe_med_hr.jpg 300w", "/static/61c292aa89909dbc64ae676d36e8c3e4/b4294/uwe_med_hr.jpg 600w", "/static/61c292aa89909dbc64ae676d36e8c3e4/4b190/uwe_med_hr.jpg 800w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <LinkToPdf title="Eine Geschichte der Familie Bertram" pdf="2012_bertram_family_neu_.pdf" mdxType="LinkToPdf" />
    <h2>{`Reiseberichte`}</h2>
    <h3>{`Europa`}</h3>
    <LinkToPdf title="Bamberg 2008" pdf="2008-05_reisebericht_mzig_b.pdf" mdxType="LinkToPdf" />
    <LinkToPdf title="Seested Wochenende in Stexwig 2008 (Dänisch)" pdf="2008-08_familie_weekend_see.pdf" mdxType="LinkToPdf" />
    <LinkToPdf title="Ostpreussen 2008" pdf="2008-09_reisebericht_ostpre.pdf" mdxType="LinkToPdf" />
    <LinkToPdf title="Lech/ Arlberg 2009" pdf="2009-03_reisebericht_mzig_b.pdf" mdxType="LinkToPdf" />
    <LinkToPdf title="Multihusets Jazzband in Hamburg 2009 (Dänisch)" pdf="2009-04_multihusetsjazzband.pdf" mdxType="LinkToPdf" />
    <LinkToPdf title="Wien 2010" pdf="2010-03_reisebericht_mzig_w.pdf" mdxType="LinkToPdf" />
    <LinkToPdf title="Swinemünde 2010" pdf="2010-07_reisebericht_segeln.pdf" mdxType="LinkToPdf" />
    <LinkToPdf title="Lanzarote 2011" pdf="2011-01_reisebericht_lanzer.pdf" mdxType="LinkToPdf" />
    <LinkToPdf title="Oberelbe 2011" pdf="2011-07_oberelbe_gunhild.pdf" mdxType="LinkToPdf" />
    <LinkToPdf title="Dänische Südsee 2011" pdf="2011-07_segeln_akka.pdf" mdxType="LinkToPdf" />
    <LinkToPdf title="Camino Del Norte 2012" pdf="2012_caminodelnorte_5.pdf" mdxType="LinkToPdf" />
    <LinkToPdf title="Segeln 2013" pdf="2013_akka.pdf" mdxType="LinkToPdf" />
    <LinkToPdf title="Jakobsweg 2013" pdf="2013-06_jacobsweg_nurnberg_.pdf" mdxType="LinkToPdf" />
    <LinkToPdf title="Spanien 2014" pdf="2014-06_spanien.pdf" mdxType="LinkToPdf" />
    <LinkToPdf title="Segeln mit Tom 2014" pdf="2014-08_akka_tom1.pdf" mdxType="LinkToPdf" />
    <LinkToPdf title="Mittelmeer 2014" pdf="2014-09-turkei_reisebericht.pdf" mdxType="LinkToPdf" />
    <LinkToPdf title="Berlin 2016" pdf="2016-05_berlin.pdf" mdxType="LinkToPdf" />
    <LinkToPdf title="Schlei 2016 #ThreeMenOnABoat" pdf="2016-08-three-men-on-a-boat.pdf" mdxType="LinkToPdf" />
    <LinkToPdf title="Türkei 2017" pdf="2017-02-T%C3%BCrkei-Kappadokien_15_150dpi.pdf" mdxType="LinkToPdf" />
    <LinkToPdf title="Atlantikküste und Provence 2017 (Teil 1)" pdf="2017-11-Atlantikkueste-und-Provence_Teil1.pdf" mdxType="LinkToPdf" />
    <LinkToPdf title="Atlantikküste und Provence 2017 (Teil 2)" pdf="2017-11-Atlantikkueste-und-Provence_Teil2.pdf" mdxType="LinkToPdf" />
    <LinkToPdf title="Haute Provence 2020" pdf="2020-08-04-Bulli-Provence.pdf" mdxType="LinkToPdf" />
    <LinkToPdf title="Kreuzfahrt zum Nordkap 2022" pdf="2022-Fridtjof-Nansen.pdf" mdxType="LinkToPdf" />
    <h3>{`Asien und Pazifikraum`}</h3>
    <LinkToPdf title="China 2005 (Deutsch)" pdf="2005-07_reisebericht_china_.pdf" mdxType="LinkToPdf" />
    <LinkToPdf title="China 2005 (Dänisch)" pdf="2005-07_touren_til_kina_dan.pdf" mdxType="LinkToPdf" />
    <LinkToPdf title="Thailand 2007 (Deutsch)" pdf="2007-06_reisebericht_thaila.pdf" mdxType="LinkToPdf" />
    <LinkToPdf title="Thailand 2007 (Dänisch)" pdf="2007-06_rejse_til_thailand_.pdf" mdxType="LinkToPdf" />
    <LinkToPdf title="Australien 2009/ 2010" pdf="2009-12_australien.pdf" mdxType="LinkToPdf" />
    <h3>{`Nord- und Süd-Amerika`}</h3>
    <LinkToPdf title="Brasilien 1999" pdf="1999_brasilien_nederlands.pdf" mdxType="LinkToPdf" />
    <LinkToPdf title="Südamerika 2005" pdf="2005-02_reisebericht_s.pdf" mdxType="LinkToPdf" />
    <LinkToPdf title="Kalifornien 2007" pdf="2007-10_reisebericht_califo.pdf" mdxType="LinkToPdf" />
    <LinkToPdf title="Kalifornien Januar 2008" pdf="2008-01_reisebericht_califo.pdf" mdxType="LinkToPdf" />
    <LinkToPdf title="Kalifornien Dezember 2008 (Deutsch)" pdf="2008-12_reisebericht_califo.pdf" mdxType="LinkToPdf" />
    <LinkToPdf title="Kalifornien Dezember 2008 (Dänisch)" pdf="2008-12_turen_gar_til_calif.pdf" mdxType="LinkToPdf" />
    <LinkToPdf title="Kalifornien 2010" pdf="2010-10_california.pdf" mdxType="LinkToPdf" />
    <LinkToPdf title="USA 2011" pdf="2011-10_reisebericht_usa.pdf" mdxType="LinkToPdf" />
    <LinkToPdf title="USA 2012" pdf="2012-09-usa.pdf" mdxType="LinkToPdf" />
    <LinkToPdf title="Südamerika 2012/ 2013" pdf="2012-12_sudamerika2_stand.pdf" mdxType="LinkToPdf" />
    <LinkToPdf title="USA Westküste 2013" pdf="2013-10_reisebericht-usa.pdf" mdxType="LinkToPdf" />
    <LinkToPdf title="USA West 2015 (Teil 1)" pdf="2015-usa_west_teil1v6_600dp.pdf" mdxType="LinkToPdf" />
    <LinkToPdf title="USA West 2015 (Teil 2)" pdf="2015-usa_w_teil2_43ver1_72d.pdf" mdxType="LinkToPdf" />
    <LinkToPdf title="Südamerika Kreuzfahrt 2016" pdf="2016-01-kreuzfahrt-sudameri.pdf" mdxType="LinkToPdf" />
    <LinkToPdf title="Washington 2018" pdf="2017-12-Seattle_fin4.pdf" mdxType="LinkToPdf" />
    <h3>{`Afrika`}</h3>
    <LinkToPdf title="Südafrika 2006/ 2007 (Deutsch)" pdf="2007-01-suedlichesafrika.pdf" mdxType="LinkToPdf" />
    <LinkToPdf title="Südafrika 2006/ 2007 (Dänisch)" pdf="2006-12_turen_gar_til_sydaf.pdf" mdxType="LinkToPdf" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      